<template>
	<div class="ExamResult">
		<div class="topFix">
			<h2 class="lineH38">{{ info.new_course_paper.title }}</h2>
		</div>
		<div class="contMain">
			<div class="contMainchild">
				<div>
					<img src="../assets/images/pic_b.png" />
					<h6>考试已结束，请按顺序离场</h6>
					<p>作答用时：{{ info.time }}分钟</p>
					<br />
					<el-button type="primary" @click="logout"
						>退出登录</el-button
					>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { xiaomiresult } from '../assets/api';
import { logout } from '@/views/components/assets/api.js';
import { delCookie } from '@/assets/js/cookie';
export default {
	name: 'ExamResult',
	data() {
		return {
			info: {},
		};
	},
	mounted() {
		this.xiaomiresult(
			this.$route.query.paper_id,
			this.$route.query.answer_id,
			this.$route.query.course_paper_id
		);
	},
	methods: {
		logout() {
			logout().then((res) => {
				if (res.code == 0) {
					if (sessionStorage.getItem('hosttype')) {
						sessionStorage.removeItem('infos');
						sessionStorage.removeItem('course');
						sessionStorage.removeItem('str_column');
						sessionStorage.removeItem('column_id');
						sessionStorage.removeItem('hosttype');
						delCookie('siteid');
						delCookie('infos');
						delCookie('faculty_id');
						if (window.location.href.indexOf('localhost') > -1) {
							window.location.href =
								'http://localhost:8080/exam/#/?hosttype=pcclient';
						} else {
							let hostname = window.location.hostname;
							window.location.href =
								'http://' +
								hostname +
								'/exam/#/?hosttype=pcclient';
						}
					} else {
						sessionStorage.removeItem('infos');
						sessionStorage.removeItem('course');
						sessionStorage.removeItem('str_column');
						sessionStorage.removeItem('column_id');
						delCookie('siteid');
						delCookie('infos');
						delCookie('faculty_id');
						if (window.location.href.indexOf('localhost') > -1) {
							window.location.href =
								'http://localhost:8080/exam/#/';
						} else {
							let hostname = window.location.hostname;
							window.location.href =
								'http://' + hostname + '/exam/#/';
						}
					}
				}
			});
		},
		xiaomiresult(paper_id, answer_id,course_paper_id) {
			const params = {
				paper_id: paper_id,
				answer_id: answer_id,
				course_paper_id:course_paper_id
			};
			xiaomiresult(params).then((res) => {
				if (res.code == 0) {
					this.info = res.data;
				}
			});
		},
	},
};
</script>

<style scoped lang="scss">
.topFix {
	background: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	box-sizing: border-box;
	padding: 12px 20px;
	z-index: 2;
	text-align: center;
}
.contMainchild {
}
.contMain {
	padding-top: 82px;
	.contMainchild {
		text-align: center;
		width: 1360px;
		margin: 0 auto 20px;
		height: calc(100vh - 148px);
		background: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		h6 {
			font-size: 16px;
			color: #333333;
			margin-top: 20px;
		}
		p {
			font-size: 13px;
			color: #999999;
			margin-top: 9px;
		}
	}
}
</style>
